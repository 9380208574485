import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import NavigationBanner from '../components/NavigationBanner';
import './privacy.scss';

const PrivacyPage = () => (
  <Layout
    selectedMenuItem="home"
    banner={<NavigationBanner />}
    className="privacy"
  >
    <div className="row">
      <div className="col-lg-10 col-xl-8">
        <h1>
          Privacy Policy for
          <br />
          sugarlessapp.com Website
        </h1>
        <p>
          We track analytics on this website, but not in a personal way. We gather insights
          like how many visitors the page has, which countries are they from, what percentage
          of them use smartphones and&nbsp;similar.
        </p>
        <p>
          For this, we use our own instance of
          {' '}
          <a href="https://umami.is/" target="_blank" rel="noreferrer">Umami</a>
          , a privacy-conscious analytics tool. We store the data in the EU and don&apos;t
          share it with anyone.
        </p>
        <p>
          If you would like to be omitted from these analytics, please turn on Do Not Track
          option in your browser, we will respect it.
        </p>

        <h2>&quot;Could You Be Addicted to Sugar?&quot; Quiz</h2>
        <p>
          Our
          {' '}
          <Link to="/quiz">Quiz</Link>
          {' '}
          page embeds a quiz built with
          {' '}
          <a href="https://www.tryinteract.com/" target="_blank" rel="noreferrer">Interact</a>
          . You can read
          {' '}
          <a href="https://www.tryinteract.com/" target="_blank" rel="noreferrer">Interact&apos;s privacy policy here</a>
          .
        </p>
      </div>
    </div>
  </Layout>
);

export default PrivacyPage;
